/* Sizes */

.w {
  &-100 {
    width: 100% !important;
  }
  &-75 {
    width: 75% !important;
  }
  &-50 {
    width: 50% !important;
  }
  &-25 {
    width: 25% !important;
  }
}

.h {
  &-100 {
    height: 100% !important;
  }
  &-75 {
    height: 75% !important;
  }
  &-50 {
    height: 50% !important;
  }
  &-25 {
    height: 25% !important;
  }
  &-container {
    height: calc(100vh - 80px - 24px - 100px);
  }
}

/* Margin */
.m {
  &t {
    &-0 {
      margin-top: 0 !important;
    }
    &-1 {
      margin-top: 1rem !important;
    }
    &-2 {
      margin-top: 2rem !important;
    }
    &-3 {
      margin-top: 3rem !important;
    }
    &-4 {
      margin-top: 4rem !important;
    }
  }
  &b {
    &-0 {
      margin-bottom: 0 !important;
    }
    &-1 {
      margin-bottom: 1rem !important;
    }
    &-2 {
      margin-bottom: 2rem !important;
    }
    &-3 {
      margin-bottom: 3rem !important;
    }
    &-4 {
      margin-bottom: 4rem !important;
    }
  }

  &s {
    margin-left: 0.5rem !important;
    &-0 {
      margin-left: 0 !important;
    }
    &-auto {
      margin-left: auto !important;
    }
    &-1 {
      margin-left: 1rem !important;
    }
    &-2 {
      margin-left: 2rem !important;
    }
    &-3 {
      margin-left: 3rem !important;
    }
    &-4 {
      margin-left: 4rem !important;
    }
  }

  &e {
    margin-right: 0.5rem !important;
    &-0 {
      margin-right: 0 !important;
    }
    &-auto {
      margin-right: auto !important;
    }
    &-1 {
      margin-right: 1rem !important;
    }
    &-2 {
      margin-right: 2rem !important;
    }
    &-3 {
      margin-right: 3rem !important;
    }
    &-4 {
      margin-right: 4rem !important;
    }
  }

  &x {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
    &-0 {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
    &-1 {
      margin-right: 1rem !important;
      margin-left: 1rem !important;
    }
    &-2 {
      margin-right: 2rem !important;
      margin-left: 2rem !important;
    }
    &-3 {
      margin-right: 3rem !important;
      margin-left: 3rem !important;
    }
    &-4 {
      margin-right: 4rem !important;
      margin-left: 4rem !important;
    }
  }

  &y {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
    &-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
    &-1 {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
    }
    &-2 {
      margin-top: 2rem !important;
      margin-bottom: 2rem !important;
    }
    &-3 {
      margin-top: 3rem !important;
      margin-bottom: 3rem !important;
    }
    &-4 {
      margin-top: 4rem !important;
      margin-bottom: 4rem !important;
    }
  }
}

[dir="rtl"] .m {
  &s {
    margin-left: auto !important;
    margin-right: 0.5rem !important;
    &-0 {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
    &-auto {
      margin-left: auto !important;
      margin-right: 0 !important;
    }
    &-1 {
      margin-left: 0 !important;
      margin-right: 1rem !important;
    }
    &-2 {
      margin-left: 0 !important;
      margin-right: 2rem !important;
    }
    &-3 {
      margin-left: 0 !important;
      margin-right: 3rem !important;
    }
    &-4 {
      margin-left: 0 !important;
      margin-right: 4rem !important;
    }
  }

  &e {
    margin-right: 0 !important;
    margin-left: 0.5rem !important;
    &-0 {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
    &-auto {
      margin-right: auto !important;
      margin-left: 0 !important;
    }
    &-1 {
      margin-right: 0 !important;
      margin-left: 1rem !important;
    }
    &-2 {
      margin-right: 0 !important;
      margin-left: 2rem !important;
    }
    &-3 {
      margin-right: 0 !important;
      margin-left: 3rem !important;
    }
    &-4 {
      margin-right: 0 !important;
      margin-left: 4rem !important;
    }
  }
}

/* Text */

.text {
  &-center {
    text-align: center;
  }
}

.text-truncate-1 {
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  // max-width: 400px;
  // height: $font-size*$line-height*$lines-to-show; /* Fallback for non-webkit */
  // margin: 0 auto;
  // font-size: $font-size;
  // line-height: $line-height;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-truncate-3 {
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  // max-width: 400px;
  // height: $font-size*$line-height*$lines-to-show; /* Fallback for non-webkit */
  // margin: 0 auto;
  // font-size: $font-size;
  // line-height: $line-height;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pointer {
  cursor: pointer;
}

.d-block {
  display: block !important;
}

/* Ratio */

@ratios: {
  1x1: 100%;
  4x3: calc(3 / 4 * 100%);
  16x9: calc(9 / 16 * 100%);
  21x9: calc(9 / 21 * 100%);
};

.ratio {
  position: relative;
  width: 100%;
  &::before {
    display: block;
    content: "";
  }

  > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  each(@ratios, {
    &-@{key}{
      &::before {
        padding-top: @value;
      }
    }
  });
}

// make border helper classes
.b {
  &-none {
    border: none;
  }
  &-top {
    border-top: 1px solid @border-color-split;
  }
  &-bottom {
    border-bottom: 1px solid @border-color-split;
  }
}

/* padding */
.p {
  &-0 {
    padding: 0 !important;
  }
  &t {
    &-1 {
      padding-top: 1rem !important;
    }
    &-2 {
      padding-top: 2rem !important;
    }
    &-3 {
      padding-top: 3rem !important;
    }
    &-4 {
      padding-top: 4rem !important;
    }
  }
  &b {
    &-1 {
      padding-bottom: 1rem !important;
    }
    &-2 {
      padding-bottom: 2rem !important;
    }
    &-3 {
      padding-bottom: 3rem !important;
    }
    &-4 {
      padding-bottom: 4rem !important;
    }
  }

  &x {
    &-1 {
      padding-right: 1rem !important;
      padding-left: 1rem !important;
    }
    &-2 {
      padding-right: 2rem !important;
      padding-left: 2rem !important;
    }
    &-3 {
      padding-right: 3rem !important;
      padding-left: 3rem !important;
    }
    &-4 {
      padding-right: 4rem !important;
      padding-left: 4rem !important;
    }
  }

  &y {
    &-1 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
    }
    &-2 {
      padding-top: 2rem !important;
      padding-bottom: 2rem !important;
    }
    &-3 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important;
    }
    &-4 {
      padding-top: 4rem !important;
      padding-bottom: 4rem !important;
    }
  }
}

.d-none {
  display: none !important;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  align-items: center !important;
}

audio {
  width: 100%;
}

.preview-frame {
  border: none;
  width: 100%;
  height: 80vh;
}

.ant-notification-notice-with-icon {
  max-height: 90vh;
  overflow-y: auto;
}

.flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.justify {
  &-space-between {
    justify-content: space-between;
  }
  &-center {
    justify-content: center;
  }
}
