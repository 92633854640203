.table {
  border: 2px solid @border-color-base;
  border-radius: @table-border-radius-base;
  &-filters {
    &-main {
      display: flex;
      align-items: center;
      padding: 10px 20px;
    }
    &-collapse {
      max-height: 0;
      transition: all 600ms ease-in-out;
      &-container {
        padding: 20px;
        border-top: 1px solid @border-color-base;
        background-color: @component-background;
      }
      &.opened {
        max-height: 1000px;
      }
    }
  }
  .@{ant-prefix}-table-tbody > tr > td {
    border-bottom: 1px solid @border-color-split;
  }
  .@{ant-prefix}-page-header {
    border-radius: 8px 8px 0 0;
  }
  .@{ant-prefix}-pagination {
    background-color: @component-background;
    margin: 0 !important;
    padding: 16px 0;
  }
  .pagination-info {
    text-align: center;
    display: block;
    color: @text-color-secondary;
    padding: 16px 0;
    background-color: @component-background;
    border-radius: 0 0 8px 8px;
  }
}

[dir="rtl"] {
  .table {
    .@{ant-prefix}-table-thead > tr > th {
      &:first-child {
        border-top-left-radius: 0;
        border-top-right-radius: @table-border-radius-base;
      }
      &:last-child {
        border-top-left-radius: @table-border-radius-base;
        border-top-right-radius: 0;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .ant-table {
    table {
      border: 0;
      display: flex;
      width: 100%;
      flex-direction: column;
      thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }
      tr {
        border: 1px solid @border-color-split;
        display: block;
        margin-bottom: 0.625em;
      }
      td {
        border-bottom: 1px solid @border-color-split;
        display: block;
        font-size: 0.8em;
        text-align: right;
        &::before {
          content: attr(data-label);
          float: left;
          font-weight: bold;
          text-transform: uppercase;
        }
        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }

  [dir="rtl"] {
    .ant-table {
      table {
        td {
          text-align: left;
          &::before {
            float: right;
          }
        }
      }
    }
  }
}
