@font-face {
  font-family: 'Almarai';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/almarai/v12/tssoApxBaigK_hnnS_anhng.ttf) format('truetype');
}
@font-face {
  font-family: 'Almarai';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/almarai/v12/tsstApxBaigK_hnnc1o.ttf) format('truetype');
}
@font-face {
  font-family: 'Almarai';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/almarai/v12/tssoApxBaigK_hnnS-aghng.ttf) format('truetype');
}
@font-face {
  font-family: 'Almarai';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/almarai/v12/tssoApxBaigK_hnnS_qjhng.ttf) format('truetype');
}
