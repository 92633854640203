.@{segmented-prefix-cls} {
  &-item {
    &-icon {
      margin: 0 !important;
      svg {
        font-size: 1.2em;
        position: relative;
        top: 3px;
      }
    }
    &-selected {
      svg {
        color: #fff;
      }
    }
  }
}
